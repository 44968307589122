import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook12/image/imgPortadaEbook.png"
import img1 from "@components/pageEbook/ebooks/ebook12/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook12/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook12/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook12/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook12/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook12/image/imgSilder3.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      Tendencias para Contact Center: Lo que debes saber en el 2023
    </h1>
  </>
)

const textDescription = (
  <p className="text-h2-ebook" style={{ marginTop: "20px" }}>
    Los Contact Center se enfrentan a nuevos desafíos y oportunidades para
    brindar una experiencia excepcional al cliente. Estar al tanto de las
    últimas tendencias es crucial para adaptarse a las demandas cambiantes y
    optimizar la atención al cliente.
    <br /> <br />
    Por eso, parte de las predicciones que le esperan a este tipo de empresa es
    tener un equilibrio entre la automatización y ofrecer una buena experiencia.
    Estos dos factores y más, lo hemos plasmado en este e-book.
  </p>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    La transformación digital en los centros de contacto no se trata solo de
    digitalizar procesos, sino de permitir que las nuevas tecnologías influyan
    en la forma en que el centro de contacto opera y se relaciona con los
    clientes.
    <br /> <br />
    Este cambio no se logra de manera inmediata, ya que implica una
    transformación fundamental en las operaciones de los centros de contacto.
  </p>
)
const textTwo = (
  <p>
    Según investigaciones recientes, las empresas que adoptan una estrategia
    omnicanal tienen una tasa de retención de clientes un 91% mayor que las que
    no lo hacen.
    <br /> <br />
    Los clientes esperan una experiencia de servicio digital y los centros de
    contacto omnicanal proporcionan una experiencia consistente en múltiples
    plataformas.
  </p>
)
const textThree = (
  <p>
    Este tipo de estrategia es muy útil ya que permite a los clientes manejar
    tareas básicas de manera automatizada, sin necesidad de hablar con un agente
  </p>
)

const data = {
  start: {
    support: "Ebook | Contact Center",
    title: title,
    description: textDescription,
    classtext: "",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaEbook,
  },

  slider: {
    title: true,
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Alcanzar la transformación digital en un Contact Center",
      },
      {
        key: 2,
        text: "Los beneficios de un centro de contacto omnicanal",
      },
      {
        key: 3,
        text: "Consejos antes de implementar un chatbot en un Contact Center",
      },
      {
        key: 4,
        text: "Cómo crear un autoservicio para el cliente en un Contact Center",
      },
      {
        key: 5,
        text: "Consejos para mejorar la satisfacción de los empleados",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        title: "¿Cómo alcanzar la transformación digital en un Contact Center?",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Beneficios de un centro de contacto omnicanal  ",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "¿Por qué se necesita autoservicio para el cliente?",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
}

export default data
